import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getDesignStudioState } from "../../../../redux/accessors"
import ContentBottomSheet from "./AddContentBottomSheet"
import { contentBottomNavOptions } from "../../../../data/design-studio"

const AddContentBottomNav = () => {
  const dispatch = useDispatch()
  const { bottomNavMenuItem } = useSelector(getDesignStudioState)
  return (
    <>
      <ContentBottomSheet />
      <div class="fixed bottom-0 inset-x-0 bg-gray-900 flex justify-between text-sm text-gray-300 uppercase font-mono">
        {contentBottomNavOptions.map(({ title, icon, dispatchAction }) => (
          <button
            onClick={() => dispatch(dispatchAction)}
            className={`py-5 w-full block px-1 text-center hover:bg-gray-800 ${
              bottomNavMenuItem === title ? "bg-gray-800 text-white" : ""
            }`}
          >
            {icon}
            <p className={`text-xs sm:text-xs md:text-base`}>{title}</p>
          </button>
        ))}
      </div>
    </>
  )
}

export default AddContentBottomNav
