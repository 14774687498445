import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getDesignStudioState } from "../../../../../redux/accessors"
import { Transition } from "../../../../../styles"

const Text = () => {
  const dispatch = useDispatch()
  const { content } = useSelector(getDesignStudioState)

  const defaultClass = `inline-block px-5 py-3 
    font-medium leading-snug 
    border border-transparent 
    rounded-md text-white 
    focus:outline-none focus:shadow-outline 
    hover:opacity-75
    bg-gray-700
    w-full
    py-2
    ${Transition.DEFAULT}`

  const getDimensions = w => {
    switch (w) {
      case "Add a Heading":
        return {
          fontSize: 24,
          fontColor: "black",
          fontFamily: "Sans Serif",
          rotateAngle: 0,
          spacing: {
            lineHeight: 1.4,
            letterSpacing: 0,
          },
          dimensions: {
            width: 181,
            height: 43,
          },
          pos: {
            left: 0,
            top: 92,
          },
          format: {
            bold: false,
            italics: false,
            capitalize: false,
            decoration: 'none',
            align: 'initial'
          },
        }
      case "Add a Subheading":
        return {
          fontSize: 20,
          rotateAngle: 0,
          fontColor: "black",
          fontFamily: "Sans Serif",
          spacing: {
            lineHeight: 1.4,
            letterSpacing: 0,
          },
          dimensions: {
            width: 186,
            height: 36,
          },
          pos: {
            left: 0,
            top: 92,
          },
          format: {
            bold: false,
            italics: false,
            capitalize: false,
            decoration: 'none',
            align: 'initial'
          },
        }
      default:
        return {
          fontSize: 16,
          rotateAngle: 0,
          fontColor: "black",
          fontFamily: "Sans Serif",
          spacing: {
            lineHeight: 1.4,
            letterSpacing: 0,
          },
          dimensions: {
            width: 166,
            height: 32,
          },
          pos: {
            left: 0,
            top: 92,
          },
          format: {
            bold: false,
            italics: false,
            capitalize: false,
            decoration: 'none',
            align: 'initial'
          },
        }
    }
  }

  const addText = value => {
    const contentOptions = {
      value,
      hiddenValue: value,
      color: "white",
      ...getDimensions(value),
    }
    const contentItem = {
      contentId: content?.length,
      contentType: "text",
      active: true,
      selected: true,
      locked: false,
      options: { ...contentOptions },
    }
    dispatch({ type: "ADD_CONTENT", contentItem })
    dispatch({ type: "CLOSE_CONTENT_MODAL" })
  }

  return (
    <div
      className={`pb-4 w-full flex flex-col items-start justify-start space-y-2`}
    >
      <button onClick={() => addText("Add a Heading")} className={defaultClass}>
        Add a Heading{" "}
      </button>
      <button
        onClick={() => addText("Add a Subheading")}
        className={defaultClass}
      >
        Add a Subheading{" "}
      </button>
      <button
        onClick={() => addText("Add some body text")}
        className={defaultClass}
      >
        Add some body text{" "}
      </button>
    </div>
  )
}

export default Text
