import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  TextSize,
  TextSpacing,
  TextColor,
  TextFormat,
  TextFont,
} from "./EditContentOptions"
import { usePrevious } from "../../../../hooks"
import { getDesignStudioState } from "../../../../redux/accessors"
import { motion, useAnimation } from "framer-motion"
import { isSlider } from "../../../../utilities/events/design-studio/deselectEventCheck"

// https://codesandbox.io/s/framer-motion-bottom-sheet-fixed-m2vls
function Sheet({ isOpen, onClose, onOpen, yDrag = true, children, ...rest }) {
  const prevIsOpen = usePrevious(isOpen)
  const controls = useAnimation()
  function onDragEnd(event, info) {
    const shouldClose = info.velocity.y > 20
    if (shouldClose) {
      controls.start("hidden")
      onClose()
    }
  }

  useEffect(() => {
    if (prevIsOpen && !isOpen) {
      controls.start("hidden")
    } else if (!prevIsOpen && isOpen) {
      controls.start("visible")
    }
  }, [controls, isOpen, prevIsOpen])

  return (
    // TODO - keep sheet solid while editing
    <motion.div
      drag={yDrag ? "y" : false}
      onDragEnd={onDragEnd}
      initial="hidden"
      animate={controls}
      className={`content-option inline-block bg-gray-50 border border-gray-200 w-full py-2 px-4`}
      transition={{
        type: "spring",
        damping: 40,
        stiffness: 400,
      }}
      variants={{
        visible: { y: "0%" },
        hidden: { y: "100%" },
      }}
      dragConstraints={{ top: 0 }}
      dragElastic={0.2}
      style={{
        height: "100%",
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
      }}
    >
      {children}
    </motion.div>
  )
}

const EditContentBottomSheet = () => {
  const dispatch = useDispatch()
  const { editContentBottomSheet } = useSelector(getDesignStudioState)
  const [yDrag, setYDrag] = useState(true)
  const {
    isOpen: isEditContentOpen,
    type: contentBottomSheetType,
  } = editContentBottomSheet

  function close() {
    dispatch({ type: "CLOSE_EDIT_CONTENT_SHEET" })
  }

  function open() {
    dispatch({ type: "OPEN_EDIT_CONTENT_SHEET" })
  }
  // prevents x scroll on sliders
  useEffect(() => {
    const node = document.getElementsByTagName("html")
    isEditContentOpen
      ? (node[0].style[`overflow-x`] = "hidden")
      : (node[0].style[`overflow-x`] = "unset")
  }, [isEditContentOpen])

  useEffect(() => {
    const yDragSetter = e => {
      setYDrag(!isSlider(e))
    }
    document.addEventListener("mousedown", yDragSetter)
    document.addEventListener("touchstart", yDragSetter)
    return () => {
      document.removeEventListener("mousedown", yDragSetter)
      document.removeEventListener("touchstart", yDragSetter)
    }
  }, [])

  return (
    <div
      className={`content-option w-full fixed bottom-0 transform transition duration-500 ease-in-out
      ${!isEditContentOpen ? `translate-y-full` : `translate-y-0`}`}
    >
      <Sheet
        onOpen={open}
        isOpen={isEditContentOpen}
        onClose={close}
        yDrag={yDrag}
      >
        <div
          className={`block h-1 w-8 bg-gray-300 rounded-full mx-auto mb-4`}
        />

        <div
          className={`flex flex-col items-start justify-start content-option `}
        >
          {contentBottomSheetType === "text-size" && <TextSize />}
          {contentBottomSheetType === "text-font" && <TextFont />}
          {contentBottomSheetType === "text-spacing" && <TextSpacing />}
          {contentBottomSheetType === "text-color" && <TextColor />}
          {contentBottomSheetType === "text-format" && <TextFormat />}
        </div>
      </Sheet>
    </div>
  )
}

export default EditContentBottomSheet
