import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { getDesignStudioState } from "../../../../../../../redux/accessors"

const TextFont = () => {
  const dispatch = useDispatch()
  const { selectedContentId, content } = useSelector(getDesignStudioState)
  if (selectedContentId === null) return null
  const { options } = content[selectedContentId]
  const { fontFamily } = options

  const selectFontFamily = value => {
    dispatch({
      type: "DESIGN_LAB_UPDATE_CONTENT",
      key: `options.fontFamily`,
      value,
    })
  }

  const buttonClasses = `py-4 px-2 w-full 
    rounded-lg border content-option text-xl 
    text-gray-800 shadow-sm 
    hover:text-gray-800 hover:bg-gray-100 hover:border-gray-300 hover:shadow 
    active:bg-white active:border-white active:shadow-none`
  const activeClass = `bg-gray-100`
  const inactiveClass = `bg-white`
  const fonts = [
    {
      title: "Playlist",
      func: () => selectFontFamily("Playlist"),
      styleButtonClasses: `${
        fontFamily === "Playlist" ? activeClass : inactiveClass
      } Playlist`,
    },
    {
      title: "Old London",
      func: () => selectFontFamily("Old-London"),
      styleButtonClasses: `${
        fontFamily === "Old-London" ? activeClass : inactiveClass
      } Old-London`,
    },
    {
      title: "Sans Serif",
      func: () => selectFontFamily("Sans Serif"),
      styleButtonClasses: `${
        fontFamily === "Sans Serif" ? activeClass : inactiveClass
      } font-sans`,
    },
  ]

  return (
    <div className={`w-full content-option`}>
      <p className={`text-base font-bold pb-4 content-option`}>Font</p>
      <div className={`w-full`}>
        <div class="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-3 content-option text-xs item-start">
          {fonts.map(({ title, func, styleButtonClasses }, index) => (
            <button
              className={`content-option ${
                index === fonts?.length - 1 ? `col-span-2 lg:col-span-1` : `col-span-1`
              }`}
              onClick={func}
            >
              <div className={`${buttonClasses} ${styleButtonClasses}`}>
                {title}
              </div>
            </button>
          ))}
        </div>
      </div>
      <br />
      <br />
    </div>
  )
}

export default TextFont
