import React, { useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getDesignStudioState } from "../../../../redux/accessors"
import { motion, useAnimation } from "framer-motion"
import { addContentOptions } from "../../../../data/design-studio"

// https://codesandbox.io/s/framer-motion-bottom-sheet-fixed-m2vls
function Sheet({ isOpen, onClose, onOpen, children, ...rest }) {
  const prevIsOpen = usePrevious(isOpen)
  const controls = useAnimation()

  function onDragEnd(event, info) {
    const shouldClose =
      info.velocity.y > 20 || (info.velocity.y >= 0 && info.point.y > 45)
    if (shouldClose) {
      controls.start("hidden")
      onClose()
    } else {
      controls.start("visible")
      onOpen()
    }
  }

  useEffect(() => {
    if (prevIsOpen && !isOpen) {
      controls.start("hidden")
    } else if (!prevIsOpen && isOpen) {
      controls.start("visible")
    }
  }, [controls, isOpen, prevIsOpen])

  return (
    <motion.div
      drag="y"
      onDragEnd={onDragEnd}
      initial="hidden"
      animate={controls}
      className={`inline-block bg-gray-800 border border-gray-800 w-full pt-2 px-4`}
      transition={{
        type: "spring",
        damping: 40,
        stiffness: 400,
      }}
      variants={{
        visible: { y: 128 },
        hidden: { y: "90%" },
      }}
      dragConstraints={{ top: 128 }}
      dragElastic={0.2}
      style={{
        height: "100%",
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
      }}
    >
      {children}
    </motion.div>
  )
}

function usePrevious(value) {
  const previousValueRef = useRef()

  useEffect(() => {
    previousValueRef.current = value
  }, [value])

  return previousValueRef.current
}

const AddContentBottomSheet = () => {
  const dispatch = useDispatch()
  const { bottomNavMenuItem, openContentModal } = useSelector(
    getDesignStudioState
  )

  function close() {
    dispatch({ type: "CLOSE_CONTENT_MODAL" })
  }

  function open() {
    dispatch({ type: "OPEN_CONTENT_MODAL" })
  }

  return (
    <div
      className={`fixed bottom-0 w-full pt-2 h-screen ${
        openContentModal ? `bg-modalshadow` : ``
      }`}
    >
      <Sheet onOpen={open} isOpen={openContentModal} onClose={close}>
        <div
          className={`block h-1 w-8 bg-gray-300 rounded-full mx-auto mb-4`}
        />

        <div className={`flex flex-col items-start justify-start`}>
          {bottomNavMenuItem && addContentOptions[bottomNavMenuItem]
            ? addContentOptions[bottomNavMenuItem].map(({ title, content }) => (
                <>
                  <p
                    className={`text-lg font-bold text-white pb-4`}
                  >
                    {" "}
                    {title}{" "}
                  </p>
                  {content}
                </>
              ))
            : null}
        </div>
      </Sheet>
    </div>
  )
}

export default AddContentBottomSheet
