import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getDesignStudioState, getCartState } from "../../../../redux/accessors"
import EditContentBottomSheet from "./EditContentBottomSheet"

const MainBottomNav = () => {
  const dispatch = useDispatch()
  const { selectedContentId, content, productHtmlId } = useSelector(
    getDesignStudioState
  )
  const { posting } = useSelector(getCartState)
  const contentIsSelected = selectedContentId !== null
  const contentIsLocked = contentIsSelected
    ? content[selectedContentId].locked
    : false
  const contentButtonClasses = `content-option w-full block px-4 text-center hover:bg-gray-100 hover:text-blue-800
  flex flex-col items-center 
  text-xs sm:text-xs md:text-base
  `
  return (
    <>
      <div
        class={`fixed bottom-0 inset-x-0 bg-gray-50 border-t border-gray-200 flex justify-between text-sm text-annmade-1.0-navy uppercase font-mono py-5 overflow-scroll sm:overflow-scroll lg:overflow-x-hidden`}
      >
        {contentIsSelected && contentIsLocked ? (
          <button
            onClick={() => dispatch({ type: "UNLOCK_SELECTED_CONTENT" })}
            class={contentButtonClasses}
          >
            {/* <FaUnlock/> icon, but had to add content-option in class to path  */}
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 448 512"
              class="text-3xl mb-2 mx-auto content-option h-8 md:h-10 w-8 md:w-10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className={`content-option`}
                d="M400 256H152V152.9c0-39.6 31.7-72.5 71.3-72.9 40-.4 72.7 32.1 72.7 72v16c0 13.3 10.7 24 24 24h32c13.3 0 24-10.7 24-24v-16C376 68 307.5-.3 223.5 0 139.5.3 72 69.5 72 153.5V256H48c-26.5 0-48 21.5-48 48v160c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
              ></path>
            </svg>
            Unlock
          </button>
        ) : contentIsSelected && !contentIsLocked ? (
          <>
            <button
              onClick={() => dispatch({ type: "OPEN_CONTENT_MODAL" })}
              class={contentButtonClasses}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 448 512"
                xmlns="http://www.w3.org/2000/svg"
                className={`text-3xl mb-2 mx-auto content-option h-6 md:h-10 w-6 md:w-10`}
              >
                <path
                  className={`text-3xl mb-2 mx-auto content-option`}
                  d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-32 252c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92H92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z"
                />
              </svg>
              Add
            </button>
            <button
              onClick={() => console.log("Hello")}
              class={contentButtonClasses}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 576 512"
                xmlns="http://www.w3.org/2000/svg"
                className={`text-4xl mb-2 mx-auto content-option h-6 md:h-10 w-6 md:w-10`}
              >
                <path
                  className={`text-4xl mb-2 mx-auto content-option`}
                  d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z"
                />
              </svg>
              Edit
            </button>
            <button
              onClick={() => dispatch({ type: "DELETE_SELECTED_CONTENT" })}
              class={contentButtonClasses}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 448 512"
                xmlns="http://www.w3.org/2000/svg"
                className={`text-3xl mb-2 mx-auto content-option h-6 md:h-10 w-6 md:w-10`}
              >
                <path
                  className={`text-3xl mb-2 mx-auto content-option`}
                  d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"
                />
              </svg>
              Delete
            </button>
            <button
              onClick={() => dispatch({ type: "DUPLICATE_SELECTED_CONTENT" })}
              class={contentButtonClasses}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 448 512"
                class="text-3xl mb-2 mx-auto content-option h-6 md:h-10 w-6 md:w-10"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className={`content-option`}
                  d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"
                />
              </svg>
              Duplicate
            </button>
            <button
              onClick={() =>
                dispatch({
                  type: "OPEN_EDIT_CONTENT_SHEET",
                  editContentType: "text-font",
                })
              }
              class={contentButtonClasses}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className={`text-3xl mb-2 mx-auto content-option h-6 md:h-10 w-6 md:w-10`}
              >
                <path
                  className={`text-3xl mb-2 mx-auto content-option`}
                  d="m11.307 4-6 16h2.137l1.875-5h6.363l1.875 5h2.137l-6-16h-2.387zm-1.239 9L12.5 6.515 14.932 13h-4.864z"
                />
              </svg>
              Font
            </button>
            <button
              onClick={() =>
                dispatch({
                  type: "OPEN_EDIT_CONTENT_SHEET",
                  editContentType: "text-size",
                })
              }
              class={contentButtonClasses}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className={`text-3xl mb-2 mx-auto content-option h-6 md:h-10 w-6 md:w-10`}
              >
                <path
                  className={`text-3xl mb-2 mx-auto content-option`}
                  d="m22 6-3-4-3 4h2v4h-2l3 4 3-4h-2V6zM9.307 4l-6 16h2.137l1.875-5h6.363l1.875 5h2.137l-6-16H9.307zm-1.239 9L10.5 6.515 12.932 13H8.068z"
                />
              </svg>
              Size
            </button>
            <button
              onClick={() =>
                dispatch({
                  type: "OPEN_EDIT_CONTENT_SHEET",
                  editContentType: "text-color",
                })
              }
              class={contentButtonClasses}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                className={`text-3xl mb-2 mx-auto content-option h-6 md:h-10 w-6 md:w-10`}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="content-option"
                  d="M20 13.998c-.092.065-2 2.083-2 3.5 0 1.494.949 2.448 2 2.5.906.044 2-.891 2-2.5C22 15.998 20.092 14.063 20 13.998zM3.414 12.998c0 .534.208 1.036.586 1.414l5.586 5.586c.378.378.88.586 1.414.586s1.036-.208 1.414-.586l7-7-.707-.707L11 4.584 8.707 2.291 7.293 3.705l2.293 2.293L4 11.584C3.622 11.962 3.414 12.464 3.414 12.998zM11 7.412l5.586 5.586L11 18.584h.001l-.001 1v-1l-5.586-5.586L11 7.412z"
                />
              </svg>
              Color
            </button>
            <button
              onClick={() =>
                dispatch({
                  type: "OPEN_EDIT_CONTENT_SHEET",
                  editContentType: "text-format",
                })
              }
              class={contentButtonClasses}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className={`text-3xl mb-2 mx-auto content-option h-6 md:h-10 w-6 md:w-10`}
              >
                <path
                  className={`text-3xl mb-2 mx-auto content-option`}
                  d="M15 4h7v2h-7zm1 4h6v2h-6zm2 4h4v2h-4zM9.307 4l-6 16h2.137l1.875-5h6.363l1.875 5h2.137l-6-16H9.307zm-1.239 9L10.5 6.515 12.932 13H8.068z"
                />
              </svg>
              Format
            </button>
            <button
              onClick={() =>
                dispatch({
                  type: "OPEN_EDIT_CONTENT_SHEET",
                  editContentType: "text-spacing",
                })
              }
              class={contentButtonClasses}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className={`text-3xl mb-2 mx-auto content-option h-6 md:h-10 w-6 md:w-10`}
              >
                <path
                  className="content-option"
                  d="M17 13L7 13 7 9 5 9 5 15 19 15 19 9 17 9z"
                />
              </svg>
              Spacing
            </button>
            {/* <button
              onClick={() => dispatch({ type: "LOCK_SELECTED_CONTENT" })}
              class={contentButtonClasses}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className={`text-3xl mb-2 mx-auto content-option h-6 md:h-10 w-6 md:w-10`}
              >
                <path
                  className={`text-3xl mb-2 mx-auto content-option`}
                  d="M12 2C9.243 2 7 4.243 7 7v3H6c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-8c0-1.103-.897-2-2-2h-1V7c0-2.757-2.243-5-5-5zm6 10 .002 8H6v-8h12zm-9-2V7c0-1.654 1.346-3 3-3s3 1.346 3 3v3H9z"
                />
              </svg>
              Lock
            </button> */}
          </>
        ) : (
          <>
            <button
              onClick={() => dispatch({ type: "OPEN_CONTENT_MODAL" })}
              class={contentButtonClasses}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 448 512"
                class="text-4xl mb-2 mx-auto content-option h-8 md:h-10 w-8 md:w-10"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-32 252c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92H92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z" />
              </svg>
              Add
            </button>
            <button
              onClick={() =>
                dispatch({
                  type: "DESIGN_LAB_UPDATE",
                  key: "openProductModal",
                  value: true,
                })
              }
              class={contentButtonClasses}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                className={`text-4xl mb-2 mx-auto content-option h-8 md:h-10 w-8 md:w-10`}
              >
                <path
                  className={`text-4xl mb-2 mx-auto content-option`}
                  d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h360c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H184V184h656v320c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V144c0-17.7-14.3-32-32-32zM653.3 599.4l52.2-52.2a8.01 8.01 0 0 0-4.7-13.6l-179.4-21c-5.1-.6-9.5 3.7-8.9 8.9l21 179.4c.8 6.6 8.9 9.4 13.6 4.7l52.4-52.4 256.2 256.2c3.1 3.1 8.2 3.1 11.3 0l42.4-42.4c3.1-3.1 3.1-8.2 0-11.3L653.3 599.4z"
                />
              </svg>
              <span className="w-24 sm:w-24 md:w-full">New Product</span>
            </button>
            <button
              onClick={() =>
                dispatch({
                  type: "DESIGN_LAB_UPDATE",
                  key: "content",
                  value: [],
                })
              }
              class={contentButtonClasses}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className={`text-4xl mb-2 mx-auto content-option h-8 md:h-10 w-8 md:w-10`}
              >
                <g>
                  <path
                    className={`text-4xl mb-2 mx-auto content-option`}
                    fill="none"
                    d="M0 0h24v24H0z"
                  ></path>
                  <path
                    className={`text-4xl mb-2 mx-auto content-option`}
                    d="M5.463 4.433A9.961 9.961 0 0 1 12 2c5.523 0 10 4.477 10 10 0 2.136-.67 4.116-1.81 5.74L17 12h3A8 8 0 0 0 6.46 6.228l-.997-1.795zm13.074 15.134A9.961 9.961 0 0 1 12 22C6.477 22 2 17.523 2 12c0-2.136.67-4.116 1.81-5.74L7 12H4a8 8 0 0 0 13.54 5.772l.997 1.795z"
                  />
                </g>
              </svg>
              Reset
            </button>
            <button
              onClick={() =>
                dispatch({
                  type: "DESIGN_STUDIO_ADD_TO_CART",
                  productHtmlId,
                  content,
                })
              }
              disabled={posting}
              class={contentButtonClasses}
            >
              {posting ? (
                <svg
                  className={`animate-spin text-4xl mb-2 mx-auto content-option h-7 md:h-9 w-7 md:w-9`}
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 1024 1024"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className={`text-4xl mb-2 mx-auto content-option`}
                    d="M909.1 209.3l-56.4 44.1C775.8 155.1 656.2 92 521.9 92 290 92 102.3 279.5 102 511.5 101.7 743.7 289.8 932 521.9 932c181.3 0 335.8-115 394.6-276.1 1.5-4.2-.7-8.9-4.9-10.3l-56.7-19.5a8 8 0 0 0-10.1 4.8c-1.8 5-3.8 10-5.9 14.9-17.3 41-42.1 77.8-73.7 109.4A344.77 344.77 0 0 1 655.9 829c-42.3 17.9-87.4 27-133.8 27-46.5 0-91.5-9.1-133.8-27A341.5 341.5 0 0 1 279 755.2a342.16 342.16 0 0 1-73.7-109.4c-17.9-42.4-27-87.4-27-133.9s9.1-91.5 27-133.9c17.3-41 42.1-77.8 73.7-109.4 31.6-31.6 68.4-56.4 109.3-73.8 42.3-17.9 87.4-27 133.8-27 46.5 0 91.5 9.1 133.8 27a341.5 341.5 0 0 1 109.3 73.8c9.9 9.9 19.2 20.4 27.8 31.4l-60.2 47a8 8 0 0 0 3 14.1l175.6 43c5 1.2 9.9-2.6 9.9-7.7l.8-180.9c-.1-6.6-7.8-10.3-13-6.2z"
                  />
                </svg>
              ) : (
                <svg
                  class="text-3xl mb-2 mx-auto content-option h-8 md:h-10 w-8 md:w-10"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className={`text-4xl mb-2 mx-auto content-option`}
                    d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
                  />
                </svg>
              )}
              <span className={`${posting ? `mt-2` : ``} w-24 sm:w-24 md:w-full`}>
                {posting ? `Adding...` : `Add to Cart`}
              </span>
            </button>
          </>
        )}
      </div>
      <EditContentBottomSheet />
    </>
  )
}

export default MainBottomNav
