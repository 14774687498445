import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { isMobile } from "../../../../../../../hooks"
import { getDesignStudioState } from "../../../../../../../redux/accessors"

const TextFormat = () => {
  const dispatch = useDispatch()
  const { selectedContentId, content } = useSelector(
    getDesignStudioState
  )
  const mobile = isMobile()

  if (selectedContentId === null) return null
  const { options } = content[selectedContentId]
  const { format } = options
  const { bold, italics, decoration, capitalize, align } = format

  const setFormat = (key, value) => {
    dispatch({
      type: "DESIGN_LAB_UPDATE_CONTENT",
      key,
      value,
    })
  }

  const inactiveClasses = `content-option 
    flex-auto inline-flex justify-center items-center 
    space-x-2 border font-semibold focus:outline-none 
    px-3 py-2 leading-6 active:z-1 focus:z-1 -mr-px 
    border-gray-300 bg-white text-gray-800 shadow-sm 
    active:bg-white active:border-white active:shadow-none`

  const activeClasses = `content-option 
    flex-auto inline-flex justify-center items-center 
    space-x-2 border font-semibold focus:outline-none 
    px-3 py-2 leading-6 active:z-1 focus:z-1 -mr-px 
    border-gray-300 bg-gray-100 text-gray-800 shadow-sm 
    ${!mobile && `hover:text-gray-800 hover:bg-gray-100 hover:border-gray-300 hover:shadow`}
    active:bg-white active:border-white active:shadow-none`

  const formatOptions = () => (
    <div class="flex flex-row justify-center items-center content-option pb-4">
      <button
        type="button"
        onClick={() => setFormat(`options.format.bold`, !bold)}
        className={`rounded-l ${bold ? activeClasses : inactiveClasses}`}
      >
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width={bold ? "0em" : "1em"}
          viewBox="0 0 384 512"
          height="1em"
          width="1em"
          className={`content-option`}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className={`content-option`}
            d="M333.49 238a122 122 0 0 0 27-65.21C367.87 96.49 308 32 233.42 32H34a16 16 0 0 0-16 16v48a16 16 0 0 0 16 16h31.87v288H34a16 16 0 0 0-16 16v48a16 16 0 0 0 16 16h209.32c70.8 0 134.14-51.75 141-122.4 4.74-48.45-16.39-92.06-50.83-119.6zM145.66 112h87.76a48 48 0 0 1 0 96h-87.76zm87.76 288h-87.76V288h87.76a56 56 0 0 1 0 112z"
          />
        </svg>
      </button>
      <button
        type="button"
        onClick={() => setFormat(`options.format.italics`, !italics)}
        className={`${italics ? activeClasses : inactiveClasses}`}
      >
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 320 512"
          height="1em"
          width="1em"
          className={`content-option`}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className={`content-option`}
            d="M320 48v32a16 16 0 0 1-16 16h-62.76l-80 320H208a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16H16a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h62.76l80-320H112a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h192a16 16 0 0 1 16 16z"
          />
        </svg>
      </button>

      <button
        type="button"
        onClick={() =>
          setFormat(
            `options.format.decoration`,
            decoration !== "underline" ? `underline` : `none`
          )
        }
        className={`${
          decoration === "underline" ? activeClasses : inactiveClasses
        }`}
      >
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          version="1.1"
          viewBox="0 0 16 16"
          height="1em"
          width="1em"
          className={`content-option`}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className={`content-option`}
            d="M11 1h2v6.5c0 2.485-2.239 4.5-5 4.5s-5-2.015-5-4.5v-6.5h2v6.5c0 0.628 0.285 1.23 0.802 1.695 0.577 0.519 1.357 0.805 2.198 0.805s1.621-0.286 2.198-0.805c0.517-0.466 0.802-1.068 0.802-1.695v-6.5zM3 13h10v2h-10z"
          />
        </svg>
      </button>

      <button
        type="button"
        onClick={() =>
          setFormat(
            `options.format.decoration`,
            decoration !== "line-through" ? `line-through` : `none`
          )
        }
        className={`${
          decoration === "line-through" ? activeClasses : inactiveClasses
        }`}
      >
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 512 512"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
          className={`content-option`}
        >
          <path
            className={`content-option`}
            d="M496 224H293.9l-87.17-26.83A43.55 43.55 0 0 1 219.55 112h66.79A49.89 49.89 0 0 1 331 139.58a16 16 0 0 0 21.46 7.15l42.94-21.47a16 16 0 0 0 7.16-21.46l-.53-1A128 128 0 0 0 287.51 32h-68a123.68 123.68 0 0 0-123 135.64c2 20.89 10.1 39.83 21.78 56.36H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h480a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm-180.24 96A43 43 0 0 1 336 356.45 43.59 43.59 0 0 1 292.45 400h-66.79A49.89 49.89 0 0 1 181 372.42a16 16 0 0 0-21.46-7.15l-42.94 21.47a16 16 0 0 0-7.16 21.46l.53 1A128 128 0 0 0 224.49 480h68a123.68 123.68 0 0 0 123-135.64 114.25 114.25 0 0 0-5.34-24.36z"
          />
        </svg>
      </button>

      <button
        type="button"
        onClick={() => setFormat(`options.format.capitalize`, !capitalize)}
        className={`rounded-r ${capitalize ? activeClasses : inactiveClasses}`}
      >
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 512 512"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
          className={`content-option`}
        >
          <path
            className={`content-option`}
            d="M292.6 407.78l-120-320a22 22 0 00-41.2 0l-120 320a22 22 0 0041.2 15.44l36.16-96.42a2 2 0 011.87-1.3h122.74a2 2 0 011.87 1.3l36.16 96.42a22 22 0 0041.2-15.44zm-185.84-129l43.37-115.65a2 2 0 013.74 0l43.37 115.67a2 2 0 01-1.87 2.7h-86.74a2 2 0 01-1.87-2.7zM400.77 169.5c-41.72-.3-79.08 23.87-95 61.4a22 22 0 0040.5 17.2c8.88-20.89 29.77-34.44 53.32-34.6 32.32-.22 58.41 26.5 58.41 58.85a1.5 1.5 0 01-1.45 1.5c-21.92.61-47.92 2.07-71.12 4.8-54.75 6.44-87.43 36.29-87.43 79.85 0 23.19 8.76 44 24.67 58.68C337.6 430.93 358 438.5 380 438.5c31 0 57.69-8 77.94-23.22h.06a22 22 0 1044 .19v-143c0-56.18-45-102.56-101.23-102.97zM380 394.5c-17.53 0-38-9.43-38-36 0-10.67 3.83-18.14 12.43-24.23 8.37-5.93 21.2-10.16 36.14-11.92 21.12-2.49 44.82-3.86 65.14-4.47a2 2 0 012 2.1C455 370.1 429.46 394.5 380 394.5z"
          />
        </svg>
      </button>
    </div>
  )

  const alignmentOptions = () => (
    <div class="flex flex-row justify-center items-center content-option pb-4">
      <button
        type="button"
        onClick={() => setFormat(`options.format.align`, "left")}
        className={`rounded-l ${
          align === "left" ? activeClasses : inactiveClasses
        }`}
      >
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 448 512"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
          className={`content-option`}
        >
          <path
            className={`content-option`}
            d="M12.83 352h262.34A12.82 12.82 0 0 0 288 339.17v-38.34A12.82 12.82 0 0 0 275.17 288H12.83A12.82 12.82 0 0 0 0 300.83v38.34A12.82 12.82 0 0 0 12.83 352zm0-256h262.34A12.82 12.82 0 0 0 288 83.17V44.83A12.82 12.82 0 0 0 275.17 32H12.83A12.82 12.82 0 0 0 0 44.83v38.34A12.82 12.82 0 0 0 12.83 96zM432 160H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0 256H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z"
          ></path>
        </svg>{" "}
      </button>
      <button
        type="button"
        onClick={() => setFormat(`options.format.align`, "center")}
        className={`${align === "center" ? activeClasses : inactiveClasses}`}
      >
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 448 512"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
          className={`content-option`}
        >
          <path
            className={`content-option`}
            d="M432 160H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0 256H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zM108.1 96h231.81A12.09 12.09 0 0 0 352 83.9V44.09A12.09 12.09 0 0 0 339.91 32H108.1A12.09 12.09 0 0 0 96 44.09V83.9A12.1 12.1 0 0 0 108.1 96zm231.81 256A12.09 12.09 0 0 0 352 339.9v-39.81A12.09 12.09 0 0 0 339.91 288H108.1A12.09 12.09 0 0 0 96 300.09v39.81a12.1 12.1 0 0 0 12.1 12.1z"
          ></path>
        </svg>
      </button>
      <button
        type="button"
        onClick={() => setFormat(`options.format.align`, "right")}
        className={`${align === "right" ? activeClasses : inactiveClasses}`}
      >
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 448 512"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
          className={`content-option`}
        >
          <path
            className={`content-option`}
            d="M16 224h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16zm416 192H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm3.17-384H172.83A12.82 12.82 0 0 0 160 44.83v38.34A12.82 12.82 0 0 0 172.83 96h262.34A12.82 12.82 0 0 0 448 83.17V44.83A12.82 12.82 0 0 0 435.17 32zm0 256H172.83A12.82 12.82 0 0 0 160 300.83v38.34A12.82 12.82 0 0 0 172.83 352h262.34A12.82 12.82 0 0 0 448 339.17v-38.34A12.82 12.82 0 0 0 435.17 288z"
          ></path>
        </svg>
      </button>
      <button
        type="button"
        onClick={() => setFormat(`options.format.align`, "justify")}
        className={`rounded-r ${
          align === "justify" ? activeClasses : inactiveClasses
        }`}
      >
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 448 512"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
          className={`content-option`}
        >
          <path
            className={`content-option`}
            d="M432 416H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-128H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-128H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-128H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
          ></path>
        </svg>
      </button>
    </div>
  )
  return (
    <div className={`w-full`}>
      <p className={`text-base font-bold pb-4 content-option`}>Format</p>
      {formatOptions()}
      {alignmentOptions()}
      <br />
      <br />
    </div>
  )
}

export default TextFormat
