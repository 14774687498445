import React, { useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { isMobile } from "../../../hooks"
import { Draggable } from "../../../components/Global/Gui/Form"
import {
  getDesignCupState,
  getDesignStudioState,
} from "../../../redux/accessors"
import imgSrc from "../../../images/WineGlass.jpg"

const CustomWineGlass = ({ id }) => {
  const dispatch = useDispatch()
  const { mainText, fontStyle, fontSize, alignment } = useSelector(
    getDesignCupState
  )
  const mobile = isMobile()
  const { selectedContentId, content } = useSelector(getDesignStudioState)

  const boxStyle = {
    width: "fit-content",
    cursor: "pointer",
  }
  const contentArea = useRef(null)
  const contentBox = useRef(null)

  useEffect(() => {
    if (contentBox.current && contentArea.current) {
      const box = contentArea.current
      const text = contentBox.current

      let boxWidth = box.offsetWidth
      let boxHeight = box.offsetHeight

      let textWidth = text.offsetWidth
      let textHeight = text.offsetHeight

      let fontSizeCopy = fontSize
      if (
        (textWidth + fontSizeCopy >= boxWidth ||
          textHeight + fontSizeCopy >= boxHeight) &&
        Math.floor(fontSize) <= 32
      ) {
        dispatch({
          type: "DESIGN_CUP_UPDATE",
          key: "mainText",
          value: mainText.slice(0, -1),
        })
        return
      }

      if (fontSize) {
        if (textWidth + fontSizeCopy >= boxWidth) {
          let ratio = fontSizeCopy / textWidth
          while (textWidth > boxWidth) {
            textWidth -= 0.1
          }
          dispatch({
            type: "DESIGN_CUP_UPDATE",
            key: "fontSize",
            value: textWidth * ratio - 1,
          })
        } else if (textHeight + fontSizeCopy >= boxHeight) {
          let ratio = fontSizeCopy / textHeight
          while (textWidth > boxHeight) {
            textHeight -= 0.1
          }
          dispatch({
            type: "DESIGN_CUP_UPDATE",
            key: "fontSize",
            value: textHeight * ratio - 1,
          })
        }
      }
      dispatch({
        type: "DESIGN_CUP_UPDATE",
        key: "position",
        value: {
          x: Math.floor(boxWidth / 2) - Math.floor(textWidth / 2),
          y: Math.floor(boxHeight / 2) - Math.floor(textHeight / 2),
        },
      })
    }
  }, [mainText, fontSize, fontStyle, dispatch])

  return (
    <div
      className={`xl:max-w-7xl mx-auto px-4 py-16 
        flex flex-col items-center justify-start
        relative transform transition duration-300 ease-in-out ${
          selectedContentId !== null && mobile ? "scale-125" : "scale-100"
        }`}
    >
      <div
        id={id}
        className={`
            relative
            rounded-md shadow-md 
            w-80 sm:w-80 md:w-96 
          `}
      >
        <img src={imgSrc} alt={`WineGlass`}/>
        {/* TO DO replace inline with tailwind  */}
        <div
          ref={contentArea}
          id={"DesignCupWineGlass-Content"}
          className={`${alignment} border-blue-500 absolute border-2 
          h-4/6
          w-7/12
          mx-auto
          box-border 
          overflow-hidden`}
          style={{
            left: "22%",
            top: "10%",
          }}
        >
          <div style={boxStyle} className={`cursor-pointer`}>
            <div ref={contentBox}>
              {Array.isArray(content) &&
                content.length > 0 &&
                content
                  .filter(({ active }) => active === true)
                  .map(({ contentId }) => <Draggable contentId={contentId} />)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

CustomWineGlass.defaultProps = {
  text: "",
  fontStyle: "Old-London",
  fontColor: "white-buttons",
  fontSize: 30,
  alignment: "text-center",
}
export default CustomWineGlass
