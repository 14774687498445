import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { getDesignStudioState } from "../../../../../../../redux/accessors"

const TextColor = () => {
  const dispatch = useDispatch()
  const { selectedContentId } = useSelector(getDesignStudioState)
  if (selectedContentId === null) return null

  const selectColor = value => {
    dispatch({
      type: "DESIGN_LAB_UPDATE_CONTENT",
      key: `options.fontColor`,
      value,
    })
  }

  return (
    <div className={`w-full content-option`}>
      <p className={`text-base font-bold pb-4 content-option`}>Color</p>
      <div className={`w-full`}>
        <div class="grid grid-cols-4 sm:grid-cols-4 md:grid-cols-5 gap-4 content-option text-xs">
          <button
            aria-label="Select Black"
            class="p-4 w-16 h-16 rounded-lg shadow-lg bg-black content-option"
            onClick={() => selectColor("Black")}
          />
          <button
            aria-label="Select Dark Gray"
            class="p-4 w-16 h-16 rounded-lg shadow-lg bg-gray-800 content-option"
            onClick={() => selectColor("Dark Gray")}
          />
          <button
            aria-label="Select Medium Gray"
            class="p-4 w-16 h-16 rounded-lg shadow-lg bg-gray-600 content-option"
            onClick={() => selectColor("Medium Gray")}
          />
          <button
            aria-label="Select Light Gray"
            class="p-4 w-16 h-16 rounded-lg shadow-lg bg-gray-400 content-option"
            onClick={() => selectColor("Light Gray")}
          />
          <button
            aria-label="Select White"
            class="p-4 w-16 h-16 rounded-lg shadow-lg bg-white border content-option"
            onClick={() => selectColor("White")}
          />
          <button
            aria-label="Select Light Blue"
            class="p-4 w-16 h-16 rounded-lg shadow-lg bg-vinyl-light-blue content-option"
            onClick={() => selectColor("Light Blue")}
          />
          <button
            aria-label="Select Pink"
            class="p-4 w-16 h-16 rounded-lg shadow-lg bg-vinyl-pink content-option"
            onClick={() => selectColor("Pink")}
          />
          <button
            aria-label="Select Mint Green"
            class="p-4 w-16 h-16 rounded-lg shadow-lg bg-vinyl-mint-green content-option"
            onClick={() => selectColor("Mint Green")}
          />
          <button
            aria-label="Select Yellow"
            class="p-4 w-16 h-16 rounded-lg shadow-lg bg-vinyl-yellow content-option"
            onClick={() => selectColor("Yellow")}
          />
          <button
            aria-label="Select Etched On"
            className={`content-option`}
            onClick={() => selectColor("Etched On")}
          >
            <div class="p-4 w-16 h-16 rounded-lg shadow-lg bg-gradient-to-r from-gray-400 to-gray-200 border content-option" />
            <div className="flex flex-row items-center justify-start content-option mt-2.5">
              <span className={`content-option text-gray-500`}>
                Etched &nbsp;
              </span>
              <div className="font-bold text-green-money content-option">
                +$3
              </div>
            </div>
          </button>
        </div>
      </div>
      <br />
      <br />
    </div>
  )
}

export default TextColor
