import React from "react"
const contentBottomAction = "SET_BOTTOM_NAV_MENU_ITEM"
const contentBottomNavOptions = [
  {
    title: "Text",
    icon: (
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 576 512"
        class="text-2xl mb-2 mx-auto h-8 md:h-10 w-8 md:w-10"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M304 32H16A16 16 0 0 0 0 48v96a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16v-32h56v304H80a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16h-40V112h56v32a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zm256 336h-48V144h48c14.31 0 21.33-17.31 11.31-27.31l-80-80a16 16 0 0 0-22.62 0l-80 80C379.36 126 384.36 144 400 144h48v224h-48c-14.31 0-21.32 17.31-11.31 27.31l80 80a16 16 0 0 0 22.62 0l80-80C580.64 386 575.64 368 560 368z" />
      </svg>
    ),
    dispatchAction: { type: contentBottomAction, item: "Text" },
  },
]

export default contentBottomNavOptions
