import React from "react"
import { Text } from "../../../components/PageSpecific/design-studio/design-panel/AddContentOptions"

const textOptions = [
  {
    title: "Click text to add",
    content: <Text />,
  },
]

export default textOptions
