import React from "react"
import AddContentBottomNav from "./AddContentBottomNav"
import MainBottomNav from "./MainBottomNav"
import { useSelector } from "react-redux"
import { getDesignStudioState } from "../../../../redux/accessors"

const Panel = () => {
  const { openContentModal } = useSelector(getDesignStudioState)
  return openContentModal ? <AddContentBottomNav /> : <MainBottomNav />
}

export default Panel
