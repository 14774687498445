import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { getDesignStudioState } from "../../../../../../../redux/accessors"
import { Slider } from "react-semantic-ui-range"
import AddSubtractButtons from "../../../../../../PageSpecific/cart/AddSubtractButtons"

const TextSpacing = () => {
  const dispatch = useDispatch()
  const { selectedContentId, content } = useSelector(getDesignStudioState)
  if (selectedContentId === null) return null
  const { options } = content[selectedContentId]
  const { spacing } = options
  const { lineHeight, letterSpacing } = spacing

  const letterSpacingSettings = {
    start: letterSpacing,
    min: -32,
    max: 32,
    step: 1,
    onChange: value => {
      if (value >= -32 && value <= 32) {
        dispatch({
          type: "DESIGN_LAB_UPDATE_CONTENT",
          key: `options.spacing.letterSpacing`,
          value,
        })
      }
    },
  }

  const setLetterSpacing = value => {
    dispatch({
      type: "DESIGN_LAB_UPDATE_CONTENT",
      key: `options.spacing.letterSpacing`,
      value,
    })
  }

  const lineHeightSettings = {
    start: lineHeight,
    min: 0.5,
    max: 2.5,
    step: 0.1,
    onChange: value => {
      if (value >= 0.5 && value <= 2.5) {
        dispatch({
          type: "DESIGN_LAB_UPDATE_CONTENT",
          key: `options.spacing.lineHeight`,
          value,
        })
      }
    },
  }

  const setLineHeight = value => {
    dispatch({
      type: "DESIGN_LAB_UPDATE_CONTENT",
      key: `options.spacing.lineHeight`,
      value,
    })
  }
  return (
    <>
      <p className={`text-base font-bold pb-4`}>Letter spacing</p>
      <AddSubtractButtons
        subtractFunction={() => setLetterSpacing(letterSpacing - 1)}
        addFunction={() => setLetterSpacing(letterSpacing + 1)}
        min={-32}
        max={32}
        currVal={letterSpacing}
      >
        <div className="flex-grow">
          <Slider
            className="mt-2"
            value={letterSpacing}
            color="blue"
            settings={letterSpacingSettings}
          />
        </div>
      </AddSubtractButtons>
      <br />
      <p className={`text-base font-bold pb-4`}>Line height</p>
      <AddSubtractButtons
        subtractFunction={() => setLineHeight(lineHeight - 0.1)}
        addFunction={() => setLineHeight(lineHeight + 0.1)}
        min={-32}
        max={32}
        currVal={lineHeight}
      >
        <div className="flex-grow">
          <Slider
            className="mt-2"
            value={lineHeight}
            color="blue"
            settings={lineHeightSettings}
          />
        </div>
      </AddSubtractButtons>
      <br />
      <br />
    </>
  )
}

export default TextSpacing
