import React, { useRef } from "react"
import { useSelector } from "react-redux"
import { isMobile } from "../../../hooks"
import { Draggable } from "../../../components/Global/Gui/Form"
import {
  getDesignCupState,
  getDesignStudioState,
} from "../../../redux/accessors"
import imgSrc from "../../../images/ShotGlass.jpeg"

const CustomShotGlass = ({ id }) => {
  const { alignment } = useSelector(
    getDesignCupState
  )
  const mobile = isMobile()
  const { selectedContentId, content } = useSelector(getDesignStudioState)

  const boxStyle = {
    width: "fit-content",
    cursor: "pointer",
  }
  const contentArea = useRef(null)
  const contentBox = useRef(null)

  return (
    <div
      className={`xl:max-w-7xl mx-auto px-4 py-16 
        flex flex-col items-center justify-start
        w-80 sm:w-80 md:w-96 
        relative transform transition duration-300 ease-in-out ${
          selectedContentId !== null && mobile ? "scale-125" : "scale-100"
        }`}
    >
      <div
        id={id}
        className={`
            relative
            rounded-md shadow-md 
          `}
      >
        <img src={imgSrc} alt={`Custom SHot Glass`}/>
        {/* TO DO replace inline with tailwind  */}
        <div
          ref={contentArea}
          id={"DesignCupWineGlass-Content"}
          className={`${alignment} border-blue-500 absolute border-2 
          h-4/6
          w-7/12
          mx-auto
          box-border 
          overflow-hidden`}
          style={{
            left: "20%",
            top: "10%"
          }}
        >
          <div style={boxStyle} className={`cursor-pointer`}>
            <div ref={contentBox}>
              {Array.isArray(content) && content.length > 0 && content
                .filter(({ active }) => active === true)
                .map(({ contentId }) => (
                  <Draggable contentId={contentId} />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

CustomShotGlass.defaultProps = {
  text: "",
  fontStyle: "Old-London",
  fontColor: "white-buttons",
  fontSize: 30,
  alignment: "text-center",
}
export default CustomShotGlass
