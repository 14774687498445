import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { getDesignStudioState } from "../../../../../../../redux/accessors"
import { Slider } from "react-semantic-ui-range"
import AddSubtractButtons from "../../../../../../PageSpecific/cart/AddSubtractButtons"

const TextSize = () => {
  const dispatch = useDispatch()
  const { selectedContentId, content } = useSelector(
    getDesignStudioState
  )
  // This prevents moving the window
  // useTouchMove(() => {}, { passive: true })
  // if (contentBottomSheetType !== 'text-size') return null
  if (selectedContentId === null) return null
  const { options } = content[selectedContentId]
  const { fontSize } = options

  const sliderSettings = {
    start: 0,
    min: 16,
    max: 64,
    step: 1,
    onChange: value => {
      if (value >= 16 && value <= 64) {
        dispatch({
          type: "DESIGN_LAB_UPDATE_CONTENT",
          key: `options.fontSize`,
          value,
        })
      }
    },
  }

  const setFontSize = value => {
    dispatch({
      type: "DESIGN_LAB_UPDATE_CONTENT",
      key: `options.fontSize`,
      value,
    })
  }

  return (
    <>
      <p className={`text-base font-bold pb-4`}>Size</p>
      <AddSubtractButtons
        subtractFunction={() => setFontSize(fontSize - 1)}
        addFunction={() => setFontSize(fontSize + 1)}
        min={16}
        max={64}
        currVal={fontSize}
      >
        <div className="flex-grow">
          <Slider
            className="mt-2"
            value={fontSize}
            color="blue"
            settings={sliderSettings}
          />
        </div>
      </AddSubtractButtons>
      <br />
      <br />
    </>
  )
}

export default TextSize
