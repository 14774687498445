import React from "react"
import { Button } from '../../Global/Gui/Buttons'
import { useDispatch } from "react-redux"
const Hero = () => {
  const dispatch = useDispatch()
  return (
    <div class="bg-white relative overflow-hidden">
      <div class="relative xl:max-w-7xl mx-auto px-4 py-16 lg:px-8 lg:py-32">
        <div class="text-center">
          <div class="text-sm uppercase font-bold tracking-wider mb-1 text-blue-400">
            Design Studio
          </div>
          <h2 class="text-3xl md:text-4xl font-extrabold mb-4">
            No products selected
          </h2>
          <h3 class="text-lg md:text-xl md:leading-relaxed font-medium text-gray-600 lg:w-2/3 mx-auto">
            Ready to gift priceless moments?
          </h3>
        </div>
        <div class="flex flex-col sm:flex-row sm:items-center sm:justify-center space-y-2 sm:space-y-2 sm:space-x-2 md:space-y-0 lg:space-x-4 pt-10">
            <Button 
              onClick={() =>
                dispatch({
                  type: "DESIGN_LAB_UPDATE",
                  key: "openProductModal",
                  value: true,
                })
              }>
              <span>Start Designing</span>
              <svg class="hi-solid hi-adjustments inline-block w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z"/></svg>
            </Button>
        </div>
      </div>
    </div>
  )
            }

export default Hero
