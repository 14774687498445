import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getDesignStudioState, getCartState } from "../redux/accessors"
import { useQueryParam, StringParam } from "use-query-params"
import { SiteMetadata } from "../components/Shared"
import { PageLayout } from "../components/Global/Layouts"
import { Panel, Hero } from "../components/PageSpecific/design-studio"
import {
  CustomWineGlass,
  CustomBeerMug,
  CustomShotGlass,
} from "../components/ProductSpecific"
import { SuccessModal, ProductModal } from "../components/Global/Gui/Modals"

const DesignStudio = ({ data }) => {
  const { products } = data
  const dispatch = useDispatch()
  const { addedToCart } = useSelector(getCartState)
  const { productHtmlId } = useSelector(getDesignStudioState)
  const [product] = useQueryParam("product", StringParam)

  useEffect(() => {
    return () => {
      dispatch({ type: "SET_BOTTOM_SHEET_CLOSE" })
      dispatch({ type: "DESIGN_LAB_UPDATE", key: "content", value: [] })
      dispatch({ type: "RESTART_ADDED_TO_CART" })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (product) {
      dispatch({
        type: "DESIGN_LAB_UPDATE",
        key: "productHtmlId",
        value: product,
      })
    } else {
      dispatch({
        type: "DESIGN_LAB_UPDATE",
        key: "openProductModal",
        value: true,
      })
    }
  }, [product, dispatch])
  return (
    <>
      <ProductModal products={products.nodes} />
      <SuccessModal open={addedToCart} />
      <SiteMetadata title="Design Studio" description="Product Design Studio" />

      {/* space-y-16 container xl:max-w-7xl mx-auto px-4 py-16 lg:px-8 lg:py-32
      m-0-auto flex flex-col items-center justify-center w-full md:w-1/2 xl:w-2/5 pt-4 pb-16
      */}
      <PageLayout>
        {productHtmlId ? (
          <>
            {productHtmlId === "wine-glass" && (
              <CustomWineGlass id={productHtmlId} />
            )}
            {productHtmlId === "shot-glass" && (
              <CustomShotGlass id={productHtmlId} />
            )}
            {productHtmlId === "beer-mug" && (
              <CustomBeerMug id={productHtmlId} />
            )}
          </>
        ) : (
          <Hero />
        )}
      </PageLayout>
      {productHtmlId && <Panel />}
    </>
  )
}

export default DesignStudio

export const query = graphql`
  {
    shopifyItems: allShopifyProduct(
      filter: {
        shopifyId: {
          nin: [
            "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzU2ODkyOTY0MjEwMTU="
            "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzU2OTA0MDc5NDQzNDM="
          ]
        }
      }
    ) {
      edges {
        node {
          title
          shopifyId
        }
      }
    }
    products: allContentfulShopifyItems(
      filter: {
        node_locale: { eq: "en-US" }
        shopifyId: { nin: ["Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzU2ODkyOTY0MjEwMTU="] }
      }
    ) {
      nodes {
        thumbnail {
          id
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        shopifyId
        name
        slug
        summary
      }
    }
  }
`
